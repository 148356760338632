import {config} from '@/common';
import {message} from 'antd';

function doUrl(url:string){
    let arr = url.split('/')
    if(config.api.url[arr[0]] !== undefined){
        url = config.api.url[arr[0]] + url.substring(url.indexOf('/') + 1,url.length)
    }else{
        url = config.api.url.default + url
    }
    return new URL(url)
}

function doAuthority(data:any){
    if(data.code === 2){
        message.error(data.msg)
        // 清除本地会话信息
        message.error("身份失效，请重新登陆")
        localStorage.removeItem('member')
        localStorage.removeItem('module')
        window.location.href = "/login"
    }
}

async function get(url:string,data?:any,response_type?:string){
    let url_obj = doUrl(url)
    if(data)Object.keys(data).forEach(key => {if(data[key] !== undefined)url_obj.searchParams.set(key, data[key])})
    let headers = {}
    // 设置token
    try {
        let member = JSON.parse(localStorage.getItem('member') || '{}')
        if(member.token)headers['Token-Member'] = member.token
    } catch (error) {
        
    }
    if(response_type){
        return await fetch(url_obj.href,{
            method:"GET",
            mode:'cors',
            headers
        })
        .then(res => {
            return {
                headers:res.headers,
                blob:res.blob()
            }
        })
        .catch(error => {
            message.error(error)
        });
    }else{
        return fetch(url_obj.href,{
            method:"GET",
            mode:'cors',
            headers
        })
        .then(res => res.json())
        .then(data => {
            // 权限处理
            doAuthority(data)
            if(data.code){
                message.error(data.msg)
            }
            return data
        })
    }
}

async function post(url:string,data?:any){
    let url_obj = doUrl(url)
    let headers = {
        'Content-Type': 'application/json'
    }
    // 设置token
    try {
        let member = JSON.parse(localStorage.getItem('member') || '{}')
        if(member.token)headers['Token-Member'] = member.token
    } catch (error) {
        
    }
    return fetch(url_obj.href,{
        method:"POST",
        mode:'cors',
        body:JSON.stringify(data),
        headers,
    })
    .then(res => res.json())
    .then(data => {
        // 权限处理
        doAuthority(data)
        // 提示
        if(data.code){
            message.error(data.msg)
        }else{
            message.success(data.msg)
        }
        return data
    })
}

export {get,post}