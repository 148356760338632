import { useImperativeHandle, forwardRef, get, useLocation, useEffect, useState } from '@/common'
import { Button, Form, Space, message } from 'antd';

function Main(props: any, ref: any) {
    const location = useLocation()
    const pathname = props.apiUrl || location.pathname
    const [form] = Form.useForm();
    const search_text = props.searchText || '搜索'
    const [loading, setLoading] = useState(false)

    const onFinish = (data: any) => {
        let request = {}
        if (props.searchBefore && typeof (props.searchBefore) == "function") {
            let rt = props.searchBefore(data)
            request = rt ? rt : data
        } else {
            request = data
        }
        props.nref.list.current.request(request)
    }
    // 暴露方法
    useImperativeHandle(ref, () => ({
        search: () => {
            onFinish(form.getFieldsValue())
        },
        getData: () => {
            return form.getFieldsValue()
        },
        export: (arg: any) => {
            if (loading) return
            setLoading(true)
            let data = form.getFieldsValue()
            Object.keys(arg).forEach(k => {
                if (arg[k]) data[k] = arg[k]
            })
            // if (props.searchBefore && typeof (props.searchBefore) == "function") props.searchBefore(data)

            let request = {}
            if (props.searchBefore && typeof (props.searchBefore) == "function") {
                let rt = props.searchBefore(data)
                request = rt ? rt : data
            } else {
                request = data
            }
            let hide = message.loading('数据导出中...', 0)
            get(pathname, request, 'blob').then(res => {
                res.blob.then((data: any) => {
                    hide()
                    message.success('导出成功');
                    setLoading(false)
                    let filename = decodeURIComponent(res.headers.get('Content-Disposition').match(/filename=(.*);/)[1])
                    var url = window.URL.createObjectURL(data);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = filename
                    a.click();
                })
            })
        }
    }
    ));

    useEffect(() => {
        if (props.setDefault && typeof (props.setDefault) == "function") props.setDefault(form)
        if (props.request) {
            form.setFieldsValue(props.request)
        }
    }, [])

    return (
        <Form form={form} className='search' onFinish={onFinish} hidden={props.hidden}>
            {props.children}
            <Space className="btn">
                <Button type="primary" htmlType="submit">{search_text}</Button>
                <Button onClick={() => { form.resetFields(); props.nref.list.current.request({}) }}>重置</Button>
            </Space>
        </Form>
    )
}
export default forwardRef(Main)