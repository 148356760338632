import { useState, post, useEffect, constant } from '@/common'
import { Form, Input, Button, Select } from 'antd';
import './League.scss';

const { Option } = Select;

const { TextArea } = Input

// const layout = {
//     labelCol: { span: 4 },
//     wrapperCol: { span: 20 },
// }

function Main() {
    // const api_url = '/service/league'
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        document.title = '合作加盟'
        document.querySelector('meta[name="viewport"]')?.setAttribute("content", "width=device-width,initial-scale=1")
    }, [])

    const finish = (values: any) => {
        setLoading(true)
        post('/opens/league/add', values).finally(() => {
            form.resetFields()
            setLoading(false)
        })
    }
    return (
        <div className='league'>
            <img className='banner' src="/images/onepage/league/banner.jpg"></img>
            {/* <div className='title'>云自助洗车诚邀您</div>
            <div className='slogan'>共创便捷洗车黄金赛道</div>
            <div className='describe'>
                云自助洗车利用“互联网+”新模式，推广便民、智慧自助洗车项目。在公共停车场、工业园区、高校、机关单位、科研院所、大型社区、酒店等建设便民服务智能自助洗车站。致力解决广大车友“洗车难、洗车贵、洗车不方便”等难题。现进行招商加盟，与大家一起合作建站，共创未来！
            </div>
            <div className='phone'>合作热线：13397130611</div>
            <img className='logo' src="/images/logo_big.jpg"></img> */}
            <div className='con'>
                <div className='title'>填写资料</div>
                <Form
                    form={form}
                    initialValues={{ city: '武汉市' }}
                    onFinish={finish}
                >
                    <Form.Item
                        label="姓名"
                        name="name"
                        rules={[{ required: true, message: '您的姓名为必填' }]}
                    >
                        <Input placeholder="请输入您的姓名" />
                    </Form.Item>
                    <Form.Item
                        label="联系电话"
                        name="phone"
                        rules={[
                            { required: true, message: '您的联系电话为必填' },
                            { pattern: new RegExp('^1[0-9]{10}$', "gim"), message: '手机号格式有误' }
                        ]}
                    >
                        <Input maxLength={11} placeholder="请输入您的联系电话" />
                    </Form.Item>
                    <Form.Item
                        label="加盟城市"
                        name="city"
                    >
                        <Select placeholder="请选择加盟城市">
                            {
                                constant.onepage.city.map((k: any) => {
                                    return <Option value={constant.onepage.city[k]} key={k}>{constant.onepage.city[k]}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="留言"
                        name="message"
                    >
                        <TextArea placeholder="请输入场站地址" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
                            提 交
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default Main