import { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, useRoutes, RouteObject } from 'react-router-dom';
import Panel from './views/Panel'
import Login from './views/entrance/Login';
// import Register from './views/entrance/Register';
import FindPw from './views/entrance/FindPw';
import Webview from './views/Webview'
import League from './views/onepage/League'

// 404处理
let default_url: any = null
const Not = () => {
    window.location.href = default_url ? default_url : '/login'
    return (<i></i>)
}

// 固定页面
let routes: RouteObject[] = [
    {
        element: <Panel />,
        children: []
    },
    {
        path: 'login',
        element: <Login />
    },
    // {
    //     path: 'register',
    //     element: <Register />
    // },
    {
        path: 'find_pw',
        element: <FindPw />
    },
    {
        path: 'webview/:type/:key',
        // path: 'webview/:key',
        element: <Webview />
    },
    {
        path: 'onepage/league',
        element: <League />
    },
    {
        path: '/',
        element: <Home />
    },
    {
        path: '*',
        element: <Not />
    }
]

let Routes = () => {
    return useRoutes(routes)
}

function Main() {
    getModules(JSON.parse(localStorage.getItem('module') || '[]'))
    return (
        <BrowserRouter>
            <Routes />
        </BrowserRouter>
    )
}

// 无限级处理
const getModules = (m: any) => {
    m.forEach((e: any) => {
        if (e.children) {
            getModules(e.children)
        } else if (routes[0].children) {
            if (!routes[0].children.length) default_url = e.url
            const Element = lazy(() => import(`@/views${pathToModule(e.url)}`))
            routes[0].children.push({
                path: e.url,
                element: <Suspense fallback={null}><Element ope={e.ope || []} /></Suspense>
            })
        }
    });
    // return m
}

// 路径转换模块名
const pathToModule = (path: string) => {
    let arr: string[] = path.split('/')
    let module: string[] = arr.splice(arr.length - 1, 1)[0].split('_')
    module.forEach((e, i) => {
        module[i] = e.charAt(0).toUpperCase() + e.slice(1)
    });
    return arr.join('/') + '/' + module.join('')
}

// 官网跳转
function Home(props: any) {
    useEffect(() => {
        window.location.href = '/home'
    }, [])
    return (
        <div></div>
    )
}
export default Main