import { config } from '@/common'

class Main {
    // 无限级处理
    public static unlimited = (arr: any, func = (e?: any) => { }) => {
        try {
            arr.forEach((e: any) => {
                func(e)
                if (e.children && e.children.length) this.unlimited(e.children, func)
            });
        } catch (error) {
            console.log(error);
        }
    }
    //手机号验证
    public static testPhone = (str: string) => {
        var reg = new RegExp('^1[0-9]{10}$', "gim")
        return reg.test(str)
    }
    // 验证邮箱
    public static testEmail = (str: string) => {
        var reg = new RegExp('^([a-zA-Z0-9]+[_|\\_|\\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\\_|\\.]?)*[a-zA-Z0-9]+\\.[a-zA-Z]{2,3}$', '');
        return reg.test(str)
    }
    // 获取随机字符串
    public static getRandom = (e: number) => {
        e = e || 32;
        let t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
            a = t.length,
            n = "";
        for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
        return n
    }
    // 获取时间
    public static getTime = (fmt = "YYYY-mm-dd HH:MM:SS", date = new Date()) => {
        let ret;
        const opt = {
            "Y+": date.getFullYear().toString(),        // 年
            "m+": (date.getMonth() + 1).toString(),     // 月
            "d+": date.getDate().toString(),            // 日
            "H+": date.getHours().toString(),           // 时
            "M+": date.getMinutes().toString(),         // 分
            "S+": date.getSeconds().toString()          // 秒
            // 有其他格式化字符需求可以继续添加，必须转化成字符串
        };
        for (let k in opt) {
            ret = new RegExp("(" + k + ")").exec(fmt);
            if (ret) {
                fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
            };
        };
        return fmt;
    }
    // 获取默认路由
    // public static getDefaultRoute(){
    //     let rt:string = '/'
    //     var modules = JSON.parse(localStorage.getItem('module') || '')
    //     if(!modules)return rt
    //     let getModules = (modules:any,url_p = "") => {
    //         modules.forEach((e:any) => {
    //             let url = `${url_p}/${e.path}`
    //             if(e.children){
    //                 getModules(e.children,url)
    //             }else{
    //                 if(!rt)rt = url
    //             }
    //         })
    //     }
    //     getModules(modules)
    //     return rt
    // }
    // 获取真实资源地址
    public static getRealUrl = (val: string, type: string = 'resource') => {
        if (type === 'resource') {
            return `${config.cos.url}/${val}`
        } else {
            return `${config.api.url.default}/${type}/${val}`
        }
    }

    //浮点数加法运算
    public static floatAdd(arg1: any, arg2: any) {
        var r1, r2, m;
        try { r1 = arg1.toString().split(".")[1].length } catch (e) { r1 = 0 }
        try { r2 = arg2.toString().split(".")[1].length } catch (e) { r2 = 0 }
        m = Math.pow(10, Math.max(r1, r2))
        return (arg1 * m + arg2 * m) / m
    }
    //浮点数减法运算
    public static floatSub(a: any, b: any) {
        var c, d, e;
        try {
            c = a.toString().split('.')[1].length;
        } catch (f) {
            c = 0;
        }
        try {
            d = b.toString().split('.')[1].length;
        } catch (f) {
            d = 0;
        }
        e = Math.pow(10, Math.max(c, d));
        return (this.floatMul(a, e) - this.floatMul(b, e)) / e;
    }
    //浮点数乘法运算
    public static floatMul(a: any, b: any) {
        var c = 0,
            d = a.toString(),
            e = b.toString();
        try {
            c += d.split('.')[1].length;
        } catch (f) {
            c += 0;
        }
        try {
            c += e.split('.')[1].length;
        } catch (f) {
            c += 0;
        }
        return Number(d.replace('.', '')) * Number(e.replace('.', '')) / Math.pow(10, c);
    }
    //浮点数除法运算
    public static floatDiv(a: any, b: any) {
        var c, d, e = 0,
            f = 0;
        try {
            e = a.toString().split('.')[1].length;
        } catch (g) {
            e += 0;
        }
        try {
            f = b.toString().split('.')[1].length;
        } catch (g) {
            f += 0;
        }
        c = Number(a.toString().replace('.', ''));
        d = Number(b.toString().replace('.', ''));
        return this.floatMul(c / d, Math.pow(10, f - e));
    }
    // //对象客隆
    // public static clone(obj: any) {
    //     return JSON.parse(JSON.stringify(obj))
    // }
    // 判断是否为空
    public static isEmpty(str: any) {
        try {
            str = str.toString().replace(/\s*/g, "")
        } catch (error) {
        }
        if (str === undefined || !str || str === '' || str === null) {
            return true
        } else {
            return false
        }
    }
    // blob文件下载
    public static downloadFile(res: any) {
        res.blob.then((data: any) => {
            let filename = decodeURIComponent(res.headers.get('Content-Disposition').match(/filename=(.*);/)[1])
            var url = window.URL.createObjectURL(data);
            var a = document.createElement('a');
            a.href = url;
            a.download = filename
            a.click();
        })
    }
}
export default Main