import { useState, get, useEffect, constant } from '@/common'
import { useParams } from 'react-router-dom';

function Main() {
    const params: any = useParams()
    const [html, setHtml] = useState('');

    const getConfig = () => {
        document.title = constant.config[params.key]
        get('/opens/config').then(data => {
            data = data.data
            if (data[params.key]) setHtml(data[params.key])
        })
    }
    const getAnnouncement = () => {
        get('/opens/announcement/detail/' + params.key).then(data => {
            data = data.data
            if (data) {
                document.title = data.title
                setHtml(data.content)
            }
        })
    }

    useEffect(() => {
        if (params.type === 'announcement') {
            getAnnouncement()
        } else {
            getConfig()
        }
        // getConfig()

        
        // if(params.){
        // }else{
        //     getConfig()
        // }
        // 设置viewport
        document.querySelector('meta[name="viewport"]')?.setAttribute("content", "width=device-width,initial-scale=1")
    }, [])
    return (
        <div dangerouslySetInnerHTML={{ __html: html }} className="webview"></div>
    )
}

export default Main