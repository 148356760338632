import { config, useState, useEffect, Func } from '@/common'
import { Upload } from 'antd';
import '../assets/style/upload.scss';

import {
    PlusOutlined,
} from '@ant-design/icons';

function Main(props: any) {
    const [file_list, setFileList] = useState<any>([]);
    const [default_status, setDefaultStatus] = useState(true)
    let member = {
        token: ''
    }
    try {
        member = JSON.parse(localStorage.getItem('member') || '{}')
    } catch (error) {

    }

    const onChange = (info: any) => {
        setDefaultStatus(false)
        let new_file_list = info.fileList
        let vals: any = []
        new_file_list.forEach((e: any) => {
            if (e.response && !e.response.code) {
                vals.push(e.response.data)
                e.url = Func.getRealUrl(e.response.data)
            }
        });
        if (vals.length === new_file_list.length) props.onChange(props.multiple ? vals : vals[0])
        setFileList(new_file_list)
    }

    const UploadButton =
        <div>
            <PlusOutlined />
            <div>{props.btnText || '上传图片'}</div>
        </div>

    useEffect(() => {
        if (props.value && default_status) {
            let data = props.multiple ? props.value : [props.value]
            let list: any[] = []
            data.forEach((e: string) => {
                list.push({
                    url: Func.getRealUrl(e),
                    response: {
                        data: e
                    }
                })
            });
            setFileList(list)
        }
    }, [props.value]);

    return (
        <Upload
            listType='picture-card'
            accept='image/png, image/jpeg, image/gif, image/jpg'
            className='upload'
            headers={{
                'Token-Member': member.token
            }}
            action={config.api.url.default + '/resource/upload'}
            fileList={file_list}
            {...props}
            onChange={onChange}
        >
            {!props.multiple && file_list.length > 0 ? null : UploadButton}
        </Upload>
    )
}
export default Main