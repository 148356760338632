import { useState, useEffect, forwardRef, MyTag, MyUpload } from '@/common'
import { Button, Table, Form, TimePicker, Space, Input, InputNumber, Select, Switch, message } from 'antd';
import {
    PlusOutlined
} from '@ant-design/icons';
import './FormListJson.scss';
import moment from 'moment';

function Main(props: any, ref: any) {
    const [form_data] = Form.useForm();
    const [datas, setDatas] = useState<any>([]);
    const [default_status, setDefaultStatus] = useState(true)
    const name = props.name || '参数'
    const pagination = props.pagination === undefined ? false : props.pagination

    // // 暴露方法
    // useImperativeHandle(ref, () => ({
    //     show: async (id: number) => {
    //         setVisible(true)
    //     },
    // }));

    const onChange = (datas: any) => {
        // setDefaultStatus(false)
        setDatas([...datas])
        if (datas.length) {
            props.onChange(datas)
        } else {
            props.onChange(null)
        }
        if (props.onChanged && typeof (props.onChanged) == "function") props.onChanged(datas)
    }

    useEffect(() => {
        // 执行一次
        if (default_status && props.value) {
            setDatas(props.value)
            setDefaultStatus(false)
        }
        // if (props.value && default_status) setDatas(props.value)
    }, [props])

    // 字段处理
    let columns: any = []
    props.columns.forEach((ele: any) => {
        let column: any = {
            title: ele.label,
            align: 'center',
        }
        if (ele.width) column.width = ele.width
        if (ele.input === 'Input') {
            column.render = (text: string, record: any, index: number) => (
                <Input value={record[ele.name]} onChange={(e: any) => {
                    datas[index][ele.name] = e.target.value
                    onChange(datas)
                }}  {...ele.property} />
            )
        }
        if (ele.input === 'InputNumber') {
            column.render = (text: string, record: any, index: number) => (
                <InputNumber min={1} value={record[ele.name]} onChange={(e: any) => {
                    datas[index][ele.name] = e
                    onChange(datas)
                }}  {...ele.property} />
            )
        }
        if (ele.input === 'InputNumberRange') {
            column.render = (text: string, record: any, index: number) => {
                let value = record[ele.name]
                if (!value) value = {
                    min: null,
                    max: null
                }
                return <Space>
                    <InputNumber value={value.min} onChange={(e: any) => {
                        value.min = e
                        datas[index][ele.name] = value
                        onChange(datas)
                    }}  {...ele.property} />
                    -
                    <InputNumber value={value.max} onChange={(e: any) => {
                        value.max = e
                        datas[index][ele.name] = value
                        onChange(datas)
                    }}  {...ele.property} />
                </Space>
            }
        }
        if (ele.input === 'Select') {
            column.render = (text: string, record: any, index: number) => (
                <Select
                    value={record[ele.name]}
                    placeholder={`请选择${ele.label}`}
                    onChange={(e: any) => {
                        datas[index][ele.name] = e
                        onChange(datas)
                    }} {...ele.property}>
                </Select>
            )
        }
        if (ele.input === 'MyUpload') {
            column.render = (text: string, record: any, index: number) => (
                <MyUpload value={record[ele.name]} onChange={(e: any) => {
                    console.log(e);
                    datas[index][ele.name] = e
                    onChange(datas)
                }}  {...ele.property} />
            )
        }
        if (ele.input === 'Switch') {
            column.render = (text: string, record: any, index: number) => (
                <Switch checked={record[ele.name]} onChange={(e: any) => {
                    datas[index][ele.name] = e
                    onChange(datas)
                }}  {...ele.property} />
            )
        }
        if (ele.input === 'MyTag') {
            column.render = (text: string, record: any, index: number) => (
                <MyTag value={record[ele.name]} onChange={(e: any) => {
                    datas[index][ele.name] = e
                    onChange(datas)
                }} {...ele.property} />
            )
        }
        if (ele.input === 'TimePicker.RangePicker') {
            column.render = (text: string, record: any, index: number) => {
                let value = null
                let format = 'HH:mm:ss'
                if (record[ele.name]) {
                    value = [
                        moment(record[ele.name][0], format),
                        moment(record[ele.name][1], format),
                    ]
                }
                return <TimePicker.RangePicker value={value} onChange={(e: any) => {
                    value = null
                    if (e) {
                        value = [
                            e[0].format(format),
                            e[1].format(format),
                        ]
                    }
                    datas[index][ele.name] = value
                    onChange(datas)
                }} {...ele.property} />
            }
        }
        columns.push(column)
    });

    return (
        <div className='_FormListJson'>
            <Table
                columns={[...columns, {
                    title: '操作',
                    align: 'center',
                    render: (text: string, record: any, index: number) => (
                        <Space>
                            {
                                index > 0 && <Button type="link" size="small" onClick={() => {
                                    datas.splice(index, 1)
                                    datas.splice(index - 1, 0, record)
                                    onChange(datas)
                                }}>上移</Button>
                            }
                            {
                                index < datas.length - 1 && <Button type="link" size="small" onClick={() => {
                                    datas.splice(index, 1)
                                    datas.splice(index + 1, 0, record)
                                    onChange(datas)
                                }}>下移</Button>
                            }
                            {
                                (props.delCan === undefined || props.delCan === true) &&
                                <Button type="link" danger size="small" onClick={() => {
                                    datas.splice(index, 1)
                                    onChange(datas)
                                }}>删除</Button>
                            }
                        </Space>
                    )
                }]}
                dataSource={datas}
                size="small"
                pagination={pagination}
                rowKey={(record, index: any) => {
                    return index
                }}
            >

            </Table>
            {
                (props.addCan === undefined || props.addCan === true) &&
                <Button className='add' onClick={() => {
                    // 最大数限制
                    if (props.max && datas.length >= props.max) {
                        message.error(`超过${props.max}个`)
                        return
                    }
                    let data = props.defaultValue ? { ...props.defaultValue } : {}
                    setDatas([...datas, data])
                }}>
                    <PlusOutlined />
                    添加一行
                </Button>
            }
        </div>
    )
}
export default forwardRef(Main)